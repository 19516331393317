import Select from '@paljs/ui/Select';
import { Card, CardBody } from '@paljs/ui/Card';
import { Button } from '@paljs/ui/Button';
import { InputGroup } from '@paljs/ui/Input';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { useState, useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import { GlobalStateData } from '../../storage/GlobalDataProvider';
import axios from 'axios';
import { EvaIcon } from '@paljs/ui/Icon';
import '../../styles/common.css';
import ReactPaginate from 'react-paginate';
import {
  requireCheck,
  resetRequired,
  pullDownReset,
  dateFormat,
  baseUri,
  showLoader,
  hideLoader,
  authCheck,
  appendErrorMsg,
  refreshPage,
  buildQuery,
  ADMIN,
  checkNumber,
  getPageAccessFromState,
  measures,
} from '../../utils/utils';
import { getFiltered } from '../../utils/table';
import DataTable from 'react-data-table-component';
import toast, { Toaster } from 'react-hot-toast';
import moment from 'moment';
import PdfGenerator from '../../components/PdfGenerator';
import { asyncPdf } from '../../components/asyncPdf';

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const ActionBtn = styled(Button)`
  margin-bottom: 10px;
  padding: 4px;
`;
const SelectField = styled(Select)`
  margin-bottom: 10px;
`;

const initialIteams = {
  item: '',
  tax: '',
  disc: '',
  expDelDate: '',
  description: '',
  quantity: '',
  rate: '',
  totalValue: '',
};

const initialFormInput = {
  poNumber: '*****',
  date: dateFormat(new Date()),
  transport: '',
  supplier: '',
  mode: '',
  amount: '',
  paymentDays: '',
  packingAndForward: '',
  insurance: '',
  items: [],
};

const POCreation = () => {
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [formInput, setFormInput] = useState(() => initialFormInput);
  const [btnDisable, setBtnDisable] = useState(false);
  const [pickId, setPickId] = useState('');

  const [defaultSupplier, setDefaultSupplier] = useState([]);
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [itemOptions, setItemOptions] = useState([]);

  const [defaultMode, setDefaultMode] = useState([]);

  const [tableFilter, setTableFilter] = useState('');
  const [tableData, setTableData] = useState([]);

  const [oldItems, setoldItems] = useState([]);
  const [showErr, setShowErr] = useState('');

  const authState = useContext(GlobalStateData);
  const pageAccess = getPageAccessFromState(authState);
  const [isSave, setSave] = useState(pageAccess.write === 1);
  const measureArray = measures();

  useEffect(() => {
    authCheck(authState.authData);
  }, []);

  useEffect(() => {
    getApi();
  }, [page]);

  const handlePagination = (event) => {
    setPage(event.selected + 1);
  };

  useEffect(() => {
    if (showErr.length) {
      setTimeout(() => {
        setShowErr('');
      }, 3000);
    }
  }, [showErr]);

  const AddItem = () => {
    let initialItems = { ...initialIteams };
    let newItem = [...formInput.items, { ...initialItems }];
    setFormInput({ ...formInput, items: newItem });
  };

  const handleChange = (e) => {
    resetRequired(e);
    const { name, value } = e.target;
    let final = value;
    if (name === 'amount') {
      final = checkNumber(e.target, '10.2');
    } else if (name === 'paymentDays') {
      final = checkNumber(e.target, '3.0');
    }
    setFormInput({
      ...formInput,
      [name]: final,
    });
  };

  const modeChange = (event) => {
    setFormInput({ ...formInput, mode: event.value });
    setDefaultMode({ label: event.label, value: event.value });
    pullDownReset('mode');
  };

  const supplierChange = (event) => {
    setFormInput({ ...formInput, supplier: event.value });
    setDefaultSupplier({ label: event.label, value: event.value });
    pullDownReset('supplier');
  };

  const itemInputChange = (e, index) => {
    resetRequired(e);
    let { name, value } = e.target;
    formInput.items[index][name] = value;
    const currItem = formInput.items[index];
    formInput.items[index]['totalValue'] = (
      +currItem.quantity *
      (+currItem.rate - +currItem.rate * (+currItem.disc / 100)) *
      (1 + +currItem.tax / 100)
    ).toFixed(2);
    if (name === 'quantity') {
      formInput.items[index][name] = checkNumber(e.target, '10.3');
    } else if (name === 'rate') {
      formInput.items[index][name] = checkNumber(e.target, '10.2');
    } else if (name === 'tax') {
      formInput.items[index][name] = checkNumber(e.target, '2.2');
    } else if (name === 'disc') {
      if (value > 100) {
        appendErrorMsg(e.target, e.target.parentElement, 'Value must be less than 100');
      }
    }
    setFormInput({ ...formInput, items: formInput.items });
  };

  const childItemChange = (event, index) => {
    let chk = itemDuplicateCheck(event.value, index, 'items-' + index + '-item');
    if (chk) {
      formInput.items[index]['defaultItem'] = { label: event.label, value: event.value };
      formInput.items[index]['item'] = event.value;
      formInput.items[index]['measure'] = event.measure;
      formInput.items[index]['itemStock'] = event.available > 0 ? event.available : 0;
      setFormInput({ ...formInput, items: formInput.items });
      pullDownReset('items-' + index + '-item');
      let container = document.querySelector('.pi-input-container');
      let err = container.querySelector('.error-message');
      if (err == null) {
        setBtnDisable(false);
      }
    }
  };

  const itemDuplicateCheck = (id, index, element) => {
    let el = document.getElementById(element);
    let find = formInput.items.find((x, key) => {
      return x.item == id && key != index;
    });
    if (find == undefined) {
      return true;
    } else {
      setBtnDisable(true);
      appendErrorMsg(el, undefined, 'Duplicate Item');
      return false;
    }
  };

  const removeItems = (index) => {
    let operations = formInput.items.filter((value, key) => {
      return key !== index;
    });
    setFormInput({ ...formInput, items: operations });
  };

  const clearFormData = () => {
    setSave(pageAccess.write === 1);
    setPickId('');
    setDefaultSupplier([]);
    setDefaultMode([]);
    resetRequired();
    setFormInput({ ...initialFormInput });
    setShowErr('');
    setBtnDisable(false);
  };

  const getApi = () => {
    showLoader();
    axios
      .get(
        baseUri() +
          buildQuery(
            'po-creations?sort[0]=id:desc&pagination[page]=' + page + '&pagination[pageSize]=20',
            tableFilter,
            filterColumns,
          ),
        {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        setTableData(response.data.data);
        setTotalPage(response.data.meta.pagination.pageCount);
        hideLoader();
        if (page == 1 && totalPage == 0) {
          getSuppliers();
          getItems();
        }
      })
      .catch((error) => {
        //return  error;
      });
    hideLoader();
  };

  const getSuppliers = () => {
    showLoader();

    axios
      .get(baseUri() + 'users?filters[userType][$eq]=3&fields[0]=userCode&fields[1]=name', {
        headers: {
          authorization: 'Bearer ' + authState.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let options = [];
        response.data.forEach(function (item, index) {
          options.push({ label: item.userCode + ' - ' + item.name, value: item.id });
        });
        setSupplierOptions(options);
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const getItems = () => {
    axios
      .get(baseUri() + 'items?fields[0]=itemCode&fields[1]=itemName&fields[2]=available&fields[3]=measure', {
        headers: {
          authorization: 'Bearer ' + authState.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let options = [];

        response.data.data.forEach(function (item, index) {
          options.push({
            label: item.attributes.itemCode + ' - ' + item.attributes.itemName,
            value: item.id,
            available: item.attributes.available,
            measure: item.attributes.measure,
          });
        });
        setItemOptions(options);
      })
      .catch((error) => {
        //return  error;
      });
  };

  const postApi = () => {
    const postData = {
      data: {
        ...formInput,
        paymentDays: formInput.mode === 'credit' ? formInput.paymentDays : null,
        amount: formInput.mode === 'advance' ? formInput.amount : null,
      },
    };
    let ele = document.querySelectorAll('.error-message');
    if (ele.length > 0) {
      return false;
    }
    let validate = requireCheck(postData);
    if (!validate || postData.data.items.length == 0) {
      if (postData.data.items.length == 0) {
        alert('Kindly Add a Item');
      }
      return false;
    }
    for (const key in postData['data']) {
      if (postData['data'][key] == '' || key == 'poNumber') {
        delete postData['data'][key];
      }
    }

    let uriPre = baseUri() + 'po-creations';
    let method = 'post';
    if (pickId) {
      uriPre = baseUri() + 'po-creations' + '/' + pickId;
      method = 'put';
    }
    showLoader();
    axios({
      method: method,
      url: uriPre,
      data: postData,
      headers: {
        authorization: 'Bearer ' + authState.authData,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        clearFormData();
        hideLoader();
        getApi();
      })
      .catch((error) => {
        if (error?.response?.status == 400) {
          let el = document.getElementById('graItemNum');
          appendErrorMsg(el, false, 'Gra Item number should be unique');
          hideLoader();
        }
      });
  };

  const editPOCreation = (id) => {
    showLoader();
    clearFormData();
    axios
      .get(baseUri() + `po-creations/${id}?populate[supplier][populate][0]=role&populate[items][populate][0]=item`, {
        headers: {
          authorization: 'Bearer ' + authState.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response: {}) => {
        setSave(pageAccess.edit === 1);
        response = response.data.data;
        hideLoader();
        setPickId(response.id);
        response = response.attributes;
        const aa = { ...response };
        aa.items = aa.items.map((t) => {
          return {
            ...t,
            item: t.item.data.id,
            measure: t.item.data.attributes.measure,
            defaultItem: {
              label: t.item.data.attributes.itemCode + ' - ' + t.item.data.attributes.itemName,
              value: t.item.data.id,
              available: t.item.data.attributes.available,
              measure: t.item.data.attributes.measure,
            },
          };
        });
        delete aa.createdAt;
        delete aa.updatedAt;
        delete aa.publishedAt;
        setDefaultSupplier({
          label: response.supplier.data.attributes.userCode + ' - ' + response.supplier.data.attributes.name,
          value: response.supplier.data.id,
        });
        setDefaultMode({
          label: response.mode[0].toUpperCase() + response.mode.slice(1),
          value: response.mode,
        });
        setFormInput(aa);
      })
      .catch((error) => {
        //return  error;
      });
  };

  const deletePOCreation = (id) => {
    const removeGroup = window.confirm('Would you like to delete this record?');
    if (removeGroup) {
      showLoader();
      setPickId(id);
      axios
        .delete(baseUri() + 'po-creations/' + id, {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          clearFormData();
          getApi();
        })
        .catch((error) => {
          //return  error;
        });
    }
  };

  const modeOptions = [
    {
      label: 'Credit',
      value: 'credit',
    },
    {
      label: 'Advance',
      value: 'advance',
    },
  ];

  const localeNumGetter = (num, minD = 2) => {
    return (+num).toLocaleString('en-IN', {
      minimumFractionDigits: minD,
      maximumFractionDigits: minD,
    });
  };

  const pdfTitles = ['S.No', 'Description', 'Unit', 'Quantity', 'Rate', 'Tax %', 'Value', 'Date of Dis'];

  const handlePrint = (id) => {
    axios
      .get(baseUri() + `po-creations/${id}?populate[supplier][populate][0]=role&populate[items][populate][0]=item`, {
        headers: {
          authorization: 'Bearer ' + authState.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response: {}) => {
        response = response.data.data;
        const aa = { ...response }.attributes;
        let totalValue = 0;
        const supplier = aa?.supplier?.data?.attributes;
        const supplierAddress = `${supplier?.name},\n${supplier?.address1},${supplier?.address2},${supplier?.address3},${supplier?.city},\n${supplier?.district}-${supplier?.pincode},\n${supplier?.phone1},${supplier?.phone2},\n${supplier?.email}`;
        const pdfContent = [];
        aa.items.forEach((t, i) => {
          totalValue += +t.totalValue;
          pdfContent.push([
            i + 1,
            t.item.data.attributes.itemName,
            [...measureArray.filter((a) => a.value === t?.item?.data?.attributes?.measure)][0]?.label,
            localeNumGetter(t?.quantity, 3),
            localeNumGetter(t?.rate),
            localeNumGetter(t?.tax),
            localeNumGetter(t?.totalValue),
            moment(t?.expDelDate).format('DD-MM-YY'),
          ]);
          pdfContent.push(['', t.description]);
        });
        pdfContent.push(['', '', '', '', '', 'Total', localeNumGetter(totalValue), '']);
        asyncPdf({
          documentTitle: 'po-creation',
          data: {
            tableTitles: pdfTitles,
            tableContents: pdfContent,
            toAddress: supplierAddress,
            no: aa?.poNumber,
            date: moment(aa?.date).format('DD-MM-YY'),
            transport: aa?.transport,
            payment: aa?.amount ? aa?.amount : aa?.paymentDays,
            isDays: aa?.paymentDays !== null,
          },
        });
      })
      .catch((error) => {
        //return  error;
        console.log(error);
      });
  };

  const columns = [
    {
      name: 'Action',
      cell: (row) => (
        <div className="action-container">
          <ActionBtn
            data-tag="allowRowEvents"
            className="abtn-color"
            id={'editData' + row.id}
            onClick={() => {
              editPOCreation(row.id);
            }}
          >
            {' '}
            <EvaIcon name="edit" />{' '}
          </ActionBtn>

          {pageAccess.delete === 1 && (
            <ActionBtn
              data-tag="allowRowEvents"
              className="abtndel-color"
              id={'del' + row.id}
              onClick={() => {
                deletePOCreation(row.id);
              }}
            >
              {' '}
              <EvaIcon name="trash" />{' '}
            </ActionBtn>
          )}
          <ActionBtn
            data-tag="allowRowEvents"
            className="abtnprint-color"
            onClick={() => {
              handlePrint(row.id);
            }}
          >
            {' '}
            <EvaIcon name="printer-outline" />{' '}
          </ActionBtn>
        </div>
      ),
    },
    {
      name: 'ID',
      selector: (row) => `${row.id}`,
      sortable: true,
      maxWidth: '20%',
    },
    {
      name: 'PO Num',
      selector: (row) => row.attributes.poNumber,
      sortable: true,
      maxWidth: '10%',
    },
    {
      name: 'Date',
      selector: (row) => moment(row.attributes.date).format('DD/MM/YY'),
      sortable: true,
      maxWidth: '25%',
    },
    {
      name: 'Mode',
      selector: (row) => `${row.attributes.mode}`,
      sortable: true,
      maxWidth: '20%',
    },
    {
      name: 'Amount',
      selector: (row) => `${row.attributes.amount}`,
      sortable: true,
      maxWidth: '20%',
    },
    {
      name: 'Payment Days',
      selector: (row) => `${row.attributes.paymentDays}`,
      sortable: true,
      maxWidth: '20%',
    },
    {
      name: 'Packing and Forward',
      selector: (row) => `${row.attributes.packingAndForward}`,
      sortable: true,
      maxWidth: '20%',
    },
    {
      name: 'Insurance',
      selector: (row) => `${row.attributes.insurance}`,
      sortable: true,
      maxWidth: '20%',
    },
  ];

  const filterColumns = [
    'id',
    'poNumber',
    'date',
    'transport',
    'mode',
    'amount',
    'paymentDays',
    'packingAndForward',
    'insurance',
  ];

  return (
    <>
      <SEO title="PO Creation" />
      <Row>
        <Toaster />
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header>PO Creation</header>
            <CardBody className="cardbody">
              <Row>
                <Col breakPoint={{ xs: 12, md: 3 }}>
                  <label>PO Number</label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="poNumber"
                      id="poNumber"
                      readOnly
                      placeholder="poNumber"
                      value={formInput.poNumber}
                      className="poNumber require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 3 }}>
                  <label>Date </label>
                  <Input fullWidth size="Small">
                    <input
                      type="date"
                      name="date"
                      placeholder="Date"
                      onChange={(e) => handleChange(e)}
                      value={formInput.date}
                      className="date require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 6 }}>
                  <label>Transport</label>
                  <Input fullWidth size="Small">
                    <input
                      maxLength={50}
                      type="text"
                      name="transport"
                      id="transport"
                      placeholder="Transport"
                      onChange={(e) => handleChange(e)}
                      value={formInput.transport}
                      className="transport require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 3 }}>
                  <label>Select Supplier</label>
                  <SelectField
                    fullWidth
                    onChange={(e) => supplierChange(e)}
                    options={supplierOptions}
                    id="supplier"
                    name="supplier"
                    placeholder="Select Supplier"
                    value={defaultSupplier}
                    className="pull-down supplier require"
                  />
                </Col>
                <Col breakPoint={{ xs: 12, md: 3 }}>
                  <label>Mode</label>
                  <SelectField
                    fullWidth
                    onChange={(e) => modeChange(e)}
                    options={modeOptions}
                    id="mode"
                    name="mode"
                    placeholder="Select Mode"
                    value={defaultMode}
                    className="pull-down mode"
                  />
                </Col>

                <Col breakPoint={{ xs: 12, md: 3 }}>
                  <label>Amount</label>
                  <Input fullWidth size="Small">
                    <input
                      type="number"
                      name="amount"
                      id="amount"
                      placeholder="Amount"
                      onChange={(e) => handleChange(e)}
                      value={formInput.amount}
                      disabled={formInput.mode !== 'advance'}
                      className={`advance ${formInput.mode === 'advance' ? 'require' : ''}`}
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 3 }}>
                  <label>Payment Days</label>
                  <Input fullWidth size="Small">
                    <input
                      type="number"
                      name="paymentDays"
                      id="paymentDays"
                      placeholder="Payment Days"
                      onChange={(e) => handleChange(e)}
                      value={formInput.paymentDays}
                      disabled={formInput.mode !== 'credit'}
                      className={`paymentDays ${formInput.mode === 'credit' ? 'require' : ''}`}
                    />
                  </Input>
                </Col>

                <Col breakPoint={{ xs: 12, md: 6 }}>
                  <label>Packing and Forward </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="packingAndForward"
                      id="packingAndForward"
                      placeholder="Packing & Forward"
                      onChange={(e) => handleChange(e)}
                      value={formInput.packingAndForward}
                      className="packingAndForward require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 6 }}>
                  <label>Insurance</label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="insurance"
                      id="insurance"
                      placeholder="insurance"
                      onChange={(e) => handleChange(e)}
                      value={formInput.insurance}
                      className="insurance require"
                    />
                  </Input>
                </Col>

                {formInput.items.length > 0 && (
                  <Col breakPoint={{ xs: 12 }} className="supplier-util-title">
                    Items
                  </Col>
                )}
                {formInput.items.length > 0 && (
                  <Col breakPoint={{ xs: 12, md: 12 }} className="pi-input-container m-b0">
                    {formInput.items.map((item, key) => {
                      let currUOM = measureArray.filter((a) => a.value === item.measure);
                      currUOM = currUOM[0]?.label;
                      return (
                        <div
                          key={key}
                          style={{
                            paddingLeft: '1rem',
                          }}
                        >
                          <Row key={'items-' + key} className="itemGRA-inputs">
                            <Col breakPoint={{ xs: 12, md: 2.5 }}>
                              {key == 0 && <label>Item</label>}
                              <SelectField
                                fullWidth
                                onChange={(e) => childItemChange(e, key)}
                                options={itemOptions}
                                value={item.defaultItem}
                                id={'items-' + key + '-item'}
                                placeholder="Select Item"
                                className="pull-down require"
                              />
                            </Col>

                            <Col breakPoint={{ xs: 12, md: 0.6 }} className="p-l0">
                              {key == 0 && <label>UOM. </label>}
                              <Input fullWidth size="Small">
                                <input
                                  type="text"
                                  placeholder="uom"
                                  name="uom"
                                  readOnly
                                  value={currUOM}
                                  className="uom"
                                />
                              </Input>
                            </Col>
                            <Col breakPoint={{ xs: 12, md: 1 }} className="p-l0">
                              {key == 0 && <label>Qty </label>}
                              <Input fullWidth size="Small">
                                <input
                                  type="number"
                                  placeholder="quantity"
                                  name="quantity"
                                  onChange={(e) => itemInputChange(e, key)}
                                  value={item.quantity}
                                  className="quantity require"
                                />
                              </Input>
                            </Col>
                            <Col breakPoint={{ xs: 12, md: 1 }} className="p-l0">
                              {key == 0 && <label>Rate </label>}
                              <Input fullWidth size="Small">
                                <input
                                  type="number"
                                  placeholder="Rate"
                                  name="rate"
                                  onChange={(e) => itemInputChange(e, key)}
                                  value={item.rate}
                                  className="acceptedQty require"
                                />
                              </Input>
                            </Col>
                            <Col breakPoint={{ xs: 12, md: 0.7 }} className="p-l0">
                              {key == 0 && <label>Tax%</label>}
                              <Input fullWidth size="Small">
                                <input
                                  type="number"
                                  placeholder="Tax"
                                  name="tax"
                                  onChange={(e) => itemInputChange(e, key)}
                                  value={item.tax}
                                  className="tax require"
                                />
                              </Input>
                            </Col>
                            <Col breakPoint={{ xs: 12, md: 1 }} className="p-l0">
                              {key == 0 && <label>Disc%</label>}
                              <Input fullWidth size="Small">
                                <input
                                  type="number"
                                  placeholder="Disc"
                                  name="disc"
                                  onChange={(e) => itemInputChange(e, key)}
                                  value={item.disc}
                                  className="disc require"
                                />
                              </Input>
                            </Col>
                            <Col breakPoint={{ xs: 12, md: 1 }} className="p-l0">
                              {key == 0 && <label>Total Value</label>}
                              <Input fullWidth size="Small">
                                <input
                                  type="text"
                                  readOnly
                                  placeholder="value"
                                  name="value"
                                  onChange={(e) => itemInputChange(e, key)}
                                  value={item.totalValue}
                                  className="value require"
                                />
                              </Input>
                            </Col>
                            <Col breakPoint={{ xs: 12, md: 1.3 }} className="p-l0">
                              {key == 0 && <label>Exp Del Dt:</label>}
                              <Input fullWidth size="Small">
                                <input
                                  type="date"
                                  placeholder="expDelDate"
                                  name="expDelDate"
                                  onChange={(e) => itemInputChange(e, key)}
                                  value={item.expDelDate}
                                  className="expDelDate require"
                                />
                              </Input>
                            </Col>
                            <Col breakPoint={{ xs: 12, md: 2.5 }} className="p-l0">
                              {key == 0 && <label>Description</label>}
                              <Input fullWidth size="Small">
                                <textarea
                                  maxLength={1000}
                                  type="text"
                                  placeholder="description"
                                  name="description"
                                  onChange={(e) => itemInputChange(e, key)}
                                  value={item.description}
                                  className="description require"
                                />
                              </Input>
                            </Col>

                            <div
                              onClick={() => removeItems(key)}
                              className={key == 0 ? 'div-btn-abs top20' : 'div-btn-abs top5'}
                            >
                              {key == 0 && <label className="div-full"></label>}
                              <label className="delBtn piOperationDelete div-full">
                                <span>-</span>
                              </label>
                            </div>
                          </Row>
                        </div>
                      );
                    })}
                  </Col>
                )}

                <Col breakPoint={{ xs: 12, md: 12 }} className="PiAddBtnContainer">
                  <Button onClick={() => AddItem()} className="addPibtn">
                    Add Item <EvaIcon name="plus" className="plusIcon" />
                  </Button>
                </Col>
                <Col breakPoint={{ xs: 12, md: 12 }} className="mt20">
                  {showErr && <p className="red">{showErr}</p>}
                  <Button
                    disabled={btnDisable || !isSave}
                    onClick={postApi}
                    status="Success"
                    type="button"
                    className="btnrad"
                    shape=""
                    halfWidth
                  >
                    Save
                  </Button>
                  &nbsp; &nbsp;
                  <Button status="danger" type="button" className="btnrad" shape="" halfWidth onClick={clearFormData}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header>PO Creation</header>
            <CardBody>
              <Input fullWidth size="Small">
                <input
                  type="text"
                  onChange={(e) => setTableFilter(e.target.value)}
                  placeholder="Filter"
                  name="tableFilter"
                  value={tableFilter}
                />
              </Input>
              <Button
                onClick={() => {
                  if (page == 1) {
                    getApi();
                  } else {
                    setPage(1);
                  }
                }}
              >
                Filter
              </Button>
              <DataTable
                columns={columns}
                data={tableData}
                fixedHeader
                allowOverflow={false}
                overflowY={false}
                overflowX={false}
                wrap={false}
                highlightOnHover={true}
              />
              <ReactPaginate
                className="pagination"
                breakLabel="..."
                nextLabel="Next"
                onPageChange={handlePagination}
                pageRangeDisplayed={5}
                pageCount={totalPage}
                previousLabel="Previous"
                renderOnZeroPageCount={null}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default POCreation;

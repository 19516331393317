import React, { useEffect, useState } from 'react';

export const asyncPdf = ({ documentTitle, data }: { documentTitle: any; data: any }) => {
  const worker = new Worker(new URL('../workers/pdf.worker.ts', import.meta.url));
  worker.addEventListener('message', (event) => {
    if (event.data instanceof Blob) {
      let ele = document.createElement('a');
      ele.href = URL.createObjectURL(event.data);
      ele.target = '_blank';
      ele.click();
      worker.terminate();
    }
  });
  worker.addEventListener('error', (event) => {
    worker.terminate();
  });
  worker.addEventListener('messageerror', (event) => {
    worker.terminate();
  });
  worker.postMessage({ title: documentTitle, data: data });
};
